import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Closed from './views/Closed';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path="/">
        <Closed />
      </Route>
    </Switch>
  );
}
