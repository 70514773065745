export default [
  {
    id: 1,
    label: 'United Kingdom',
    value: '+44',
    flag: 'https://www.countryflags.io/GB/flat/64.png',
  },
  {
    id: 2,
    label: 'United States',
    value: '+1',
    flag: 'https://www.countryflags.io/US/flat/64.png',
  },
  {
    id: 3,
    label: 'Canada',
    value: '+1',
    flag: 'https://www.countryflags.io/CA/flat/64.png',
  },
  {
    id: 4,
    label: 'China',
    value: '+86',
    flag: 'https://www.countryflags.io/CN/flat/64.png',
  },
  {
    id: 5,
    label: 'Hong Kong',
    value: '+852',
    flag: 'https://www.countryflags.io/HK/flat/64.png',
  },
  {
    id: 6,
    label: 'Singapore',
    value: '+65',
    flag: 'https://www.countryflags.io/SG/flat/64.png',
  },
  {
    id: 7,
    label: 'Afghanistan',
    value: '+93',
    flag: 'https://www.countryflags.io/AF/flat/64.png',
  },
  {
    id: 8,
    label: 'Aland Islands',
    value: '+358',
    flag: 'https://www.countryflags.io/AX/flat/64.png',
  },
  {
    id: 9,
    label: 'Albania',
    value: '+355',
    flag: 'https://www.countryflags.io/AL/flat/64.png',
  },
  {
    id: 10,
    label: 'Algeria',
    value: '+213',
    flag: 'https://www.countryflags.io/DZ/flat/64.png',
  },
  {
    id: 11,
    label: 'AmericanSamoa',
    value: '+1684',
    flag: 'https://www.countryflags.io/AS/flat/64.png',
  },
  {
    id: 12,
    label: 'Andorra',
    value: '+376',
    flag: 'https://www.countryflags.io/AD/flat/64.png',
  },
  {
    id: 13,
    label: 'Angola',
    value: '+244',
    flag: 'https://www.countryflags.io/AO/flat/64.png',
  },
  {
    id: 14,
    label: 'Anguilla',
    value: '+1264',
    flag: 'https://www.countryflags.io/AI/flat/64.png',
  },
  {
    id: 15,
    label: 'Antarctica',
    value: '+672',
    flag: 'https://www.countryflags.io/AQ/flat/64.png',
  },
  {
    id: 16,
    label: 'Antigua and Barbuda',
    value: '+1268',
    flag: 'https://www.countryflags.io/AG/flat/64.png',
  },
  {
    id: 17,
    label: 'Argentina',
    value: '+54',
    flag: 'https://www.countryflags.io/AR/flat/64.png',
  },
  {
    id: 18,
    label: 'Armenia',
    value: '+374',
    flag: 'https://www.countryflags.io/AM/flat/64.png',
  },
  {
    id: 19,
    label: 'Aruba',
    value: '+297',
    flag: 'https://www.countryflags.io/AW/flat/64.png',
  },
  // {
  //   id: 20,
  //   label: 'Ascension Island',
  //   value: '+247',
  //   flag: 'https://www.countryflags.io/AC/flat/64.png',
  // },
  {
    id: 21,
    label: 'Australia',
    value: '+61',
    flag: 'https://www.countryflags.io/AU/flat/64.png',
  },
  {
    id: 22,
    label: 'Austria',
    value: '+43',
    flag: 'https://www.countryflags.io/AT/flat/64.png',
  },
  {
    id: 23,
    label: 'Azerbaijan',
    value: '+994',
    flag: 'https://www.countryflags.io/AZ/flat/64.png',
  },
  {
    id: 24,
    label: 'Bahamas',
    value: '+1242',
    flag: 'https://www.countryflags.io/BS/flat/64.png',
  },
  {
    id: 25,
    label: 'Bahrain',
    value: '+973',
    flag: 'https://www.countryflags.io/BH/flat/64.png',
  },
  {
    id: 26,
    label: 'Bangladesh',
    value: '+880',
    flag: 'https://www.countryflags.io/BD/flat/64.png',
  },
  {
    id: 27,
    label: 'Barbados',
    value: '+1246',
    flag: 'https://www.countryflags.io/BB/flat/64.png',
  },
  {
    id: 28,
    label: 'Belarus',
    value: '+375',
    flag: 'https://www.countryflags.io/BY/flat/64.png',
  },
  {
    id: 29,
    label: 'Belgium',
    value: '+32',
    flag: 'https://www.countryflags.io/BE/flat/64.png',
  },
  {
    id: 30,
    label: 'Belize',
    value: '+501',
    flag: 'https://www.countryflags.io/BZ/flat/64.png',
  },
  {
    id: 31,
    label: 'Benin',
    value: '+229',
    flag: 'https://www.countryflags.io/BJ/flat/64.png',
  },
  {
    id: 32,
    label: 'Bermuda',
    value: '+1441',
    flag: 'https://www.countryflags.io/BM/flat/64.png',
  },
  {
    id: 33,
    label: 'Bhutan',
    value: '+975',
    flag: 'https://www.countryflags.io/BT/flat/64.png',
  },
  {
    id: 34,
    label: 'Bolivia',
    value: '+591',
    flag: 'https://www.countryflags.io/BO/flat/64.png',
  },
  {
    id: 35,
    label: 'Bosnia and Herzegovina',
    value: '+387',
    flag: 'https://www.countryflags.io/BA/flat/64.png',
  },
  {
    id: 36,
    label: 'Botswana',
    value: '+267',
    flag: 'https://www.countryflags.io/BW/flat/64.png',
  },
  {
    id: 37,
    label: 'Brazil',
    value: '+55',
    flag: 'https://www.countryflags.io/BR/flat/64.png',
  },
  {
    id: 38,
    label: 'British Indian Ocean Territory',
    value: '+246',
    flag: 'https://www.countryflags.io/IO/flat/64.png',
  },
  {
    id: 39,
    label: 'Brunei',
    value: '+673',
    flag: 'https://www.countryflags.io/BN/flat/64.png',
  },
  {
    id: 40,
    label: 'Bulgaria',
    value: '+359',
    flag: 'https://www.countryflags.io/BG/flat/64.png',
  },
  {
    id: 41,
    label: 'Burkina Faso',
    value: '+226',
    flag: 'https://www.countryflags.io/BF/flat/64.png',
  },
  {
    id: 42,
    label: 'Burundi',
    value: '+257',
    flag: 'https://www.countryflags.io/BI/flat/64.png',
  },
  {
    id: 43,
    label: 'Cambodia',
    value: '+855',
    flag: 'https://www.countryflags.io/KH/flat/64.png',
  },
  {
    id: 44,
    label: 'Cameroon',
    value: '+237',
    flag: 'https://www.countryflags.io/CM/flat/64.png',
  },
  {
    id: 45,
    label: 'Cape Verde',
    value: '+238',
    flag: 'https://www.countryflags.io/CV/flat/64.png',
  },
  {
    id: 46,
    label: 'Cayman Islands',
    value: '+1345',
    flag: 'https://www.countryflags.io/KY/flat/64.png',
  },
  {
    id: 47,
    label: 'Central African Republic',
    value: '+236',
    flag: 'https://www.countryflags.io/CF/flat/64.png',
  },
  {
    id: 48,
    label: 'Chad',
    value: '+235',
    flag: 'https://www.countryflags.io/TD/flat/64.png',
  },
  {
    id: 49,
    label: 'Chile',
    value: '+56',
    flag: 'https://www.countryflags.io/CL/flat/64.png',
  },
  {
    id: 50,
    label: 'Christmas Island',
    value: '+61',
    flag: 'https://www.countryflags.io/CX/flat/64.png',
  },
  {
    id: 51,
    label: 'Cocos (Keeling) Islands',
    value: '+61',
    flag: 'https://www.countryflags.io/CC/flat/64.png',
  },
  {
    id: 52,
    label: 'Colombia',
    value: '+57',
    flag: 'https://www.countryflags.io/CO/flat/64.png',
  },
  {
    id: 53,
    label: 'Comoros',
    value: '+269',
    flag: 'https://www.countryflags.io/KM/flat/64.png',
  },
  {
    id: 54,
    label: 'Congo',
    value: '+242',
    flag: 'https://www.countryflags.io/CG/flat/64.png',
  },
  {
    id: 55,
    label: 'Cook Islands',
    value: '+682',
    flag: 'https://www.countryflags.io/CK/flat/64.png',
  },
  {
    id: 56,
    label: 'Costa Rica',
    value: '+506',
    flag: 'https://www.countryflags.io/CR/flat/64.png',
  },
  {
    id: 57,
    label: 'Croatia',
    value: '+385',
    flag: 'https://www.countryflags.io/HR/flat/64.png',
  },
  {
    id: 58,
    label: 'Cuba',
    value: '+53',
    flag: 'https://www.countryflags.io/CU/flat/64.png',
  },
  {
    id: 59,
    label: 'Cyprus',
    value: '+357',
    flag: 'https://www.countryflags.io/CY/flat/64.png',
  },
  {
    id: 60,
    label: 'Czech Republic',
    value: '+420',
    flag: 'https://www.countryflags.io/CZ/flat/64.png',
  },
  {
    id: 61,
    label: 'Democratic Republic of the Congo',
    value: '+243',
    flag: 'https://www.countryflags.io/CD/flat/64.png',
  },
  {
    id: 62,
    label: 'Denmark',
    value: '+45',
    flag: 'https://www.countryflags.io/DK/flat/64.png',
  },
  {
    id: 63,
    label: 'Djibouti',
    value: '+253',
    flag: 'https://www.countryflags.io/DJ/flat/64.png',
  },
  {
    id: 64,
    label: 'Dominica',
    value: '+1767',
    flag: 'https://www.countryflags.io/DM/flat/64.png',
  },
  {
    id: 65,
    label: 'Dominican Republic',
    value: '+1849',
    flag: 'https://www.countryflags.io/DO/flat/64.png',
  },
  {
    id: 66,
    label: 'Ecuador',
    value: '+593',
    flag: 'https://www.countryflags.io/EC/flat/64.png',
  },
  {
    id: 67,
    label: 'Egypt',
    value: '+20',
    flag: 'https://www.countryflags.io/EG/flat/64.png',
  },
  {
    id: 68,
    label: 'El Salvador',
    value: '+503',
    flag: 'https://www.countryflags.io/SV/flat/64.png',
  },
  {
    id: 69,
    label: 'Equatorial Guinea',
    value: '+240',
    flag: 'https://www.countryflags.io/GQ/flat/64.png',
  },
  {
    id: 70,
    label: 'Eritrea',
    value: '+291',
    flag: 'https://www.countryflags.io/ER/flat/64.png',
  },
  {
    id: 71,
    label: 'Estonia',
    value: '+372',
    flag: 'https://www.countryflags.io/EE/flat/64.png',
  },
  {
    id: 72,
    label: 'Eswatini',
    value: '+268',
    flag: 'https://www.countryflags.io/SZ/flat/64.png',
  },
  {
    id: 73,
    label: 'Ethiopia',
    value: '+251',
    flag: 'https://www.countryflags.io/ET/flat/64.png',
  },
  {
    id: 74,
    label: 'Falkland Islands (Malvinas)',
    value: '+500',
    flag: 'https://www.countryflags.io/FK/flat/64.png',
  },
  {
    id: 75,
    label: 'Faroe Islands',
    value: '+298',
    flag: 'https://www.countryflags.io/FO/flat/64.png',
  },
  {
    id: 76,
    label: 'Fiji',
    value: '+679',
    flag: 'https://www.countryflags.io/FJ/flat/64.png',
  },
  {
    id: 77,
    label: 'Finland',
    value: '+358',
    flag: 'https://www.countryflags.io/FI/flat/64.png',
  },
  {
    id: 78,
    label: 'France',
    value: '+33',
    flag: 'https://www.countryflags.io/FR/flat/64.png',
  },
  {
    id: 79,
    label: 'French Guiana',
    value: '+594',
    flag: 'https://www.countryflags.io/GF/flat/64.png',
  },
  {
    id: 80,
    label: 'French Polynesia',
    value: '+689',
    flag: 'https://www.countryflags.io/PF/flat/64.png',
  },
  {
    id: 81,
    label: 'Gabon',
    value: '+241',
    flag: 'https://www.countryflags.io/GA/flat/64.png',
  },
  {
    id: 82,
    label: 'Gambia',
    value: '+220',
    flag: 'https://www.countryflags.io/GM/flat/64.png',
  },
  {
    id: 83,
    label: 'Georgia',
    value: '+995',
    flag: 'https://www.countryflags.io/GE/flat/64.png',
  },
  {
    id: 84,
    label: 'Germany',
    value: '+49',
    flag: 'https://www.countryflags.io/DE/flat/64.png',
  },
  {
    id: 85,
    label: 'Ghana',
    value: '+233',
    flag: 'https://www.countryflags.io/GH/flat/64.png',
  },
  {
    id: 86,
    label: 'Gibraltar',
    value: '+350',
    flag: 'https://www.countryflags.io/GI/flat/64.png',
  },
  {
    id: 87,
    label: 'Greece',
    value: '+30',
    flag: 'https://www.countryflags.io/GR/flat/64.png',
  },
  {
    id: 88,
    label: 'Greenland',
    value: '+299',
    flag: 'https://www.countryflags.io/GL/flat/64.png',
  },
  {
    id: 89,
    label: 'Grenada',
    value: '+1473',
    flag: 'https://www.countryflags.io/GD/flat/64.png',
  },
  {
    id: 90,
    label: 'Guadeloupe',
    value: '+590',
    flag: 'https://www.countryflags.io/GP/flat/64.png',
  },
  {
    id: 91,
    label: 'Guam',
    value: '+1671',
    flag: 'https://www.countryflags.io/GU/flat/64.png',
  },
  {
    id: 92,
    label: 'Guatemala',
    value: '+502',
    flag: 'https://www.countryflags.io/GT/flat/64.png',
  },
  {
    id: 93,
    label: 'Guernsey',
    value: '+44',
    flag: 'https://www.countryflags.io/GG/flat/64.png',
  },
  {
    id: 94,
    label: 'Guinea',
    value: '+224',
    flag: 'https://www.countryflags.io/GN/flat/64.png',
  },
  {
    id: 95,
    label: 'Guinea-Bissau',
    value: '+245',
    flag: 'https://www.countryflags.io/GW/flat/64.png',
  },
  {
    id: 96,
    label: 'Guyana',
    value: '+592',
    flag: 'https://www.countryflags.io/GY/flat/64.png',
  },
  {
    id: 97,
    label: 'Haiti',
    value: '+509',
    flag: 'https://www.countryflags.io/HT/flat/64.png',
  },
  {
    id: 98,
    label: 'Holy See (Vatican City State)',
    value: '+379',
    flag: 'https://www.countryflags.io/VA/flat/64.png',
  },
  {
    id: 99,
    label: 'Honduras',
    value: '+504',
    flag: 'https://www.countryflags.io/HN/flat/64.png',
  },
  {
    id: 100,
    label: 'Hungary',
    value: '+36',
    flag: 'https://www.countryflags.io/HU/flat/64.png',
  },
  {
    id: 101,
    label: 'Iceland',
    value: '+354',
    flag: 'https://www.countryflags.io/IS/flat/64.png',
  },
  {
    id: 102,
    label: 'India',
    value: '+91',
    flag: 'https://www.countryflags.io/IN/flat/64.png',
  },
  {
    id: 103,
    label: 'Indonesia',
    value: '+62',
    flag: 'https://www.countryflags.io/ID/flat/64.png',
  },
  {
    id: 104,
    label: 'Iran',
    value: '+98',
    flag: 'https://www.countryflags.io/IR/flat/64.png',
  },
  {
    id: 105,
    label: 'Iraq',
    value: '+964',
    flag: 'https://www.countryflags.io/IQ/flat/64.png',
  },
  {
    id: 106,
    label: 'Ireland',
    value: '+353',
    flag: 'https://www.countryflags.io/IE/flat/64.png',
  },
  {
    id: 107,
    label: 'Isle of Man',
    value: '+44',
    flag: 'https://www.countryflags.io/IM/flat/64.png',
  },
  {
    id: 108,
    label: 'Israel',
    value: '+972',
    flag: 'https://www.countryflags.io/IL/flat/64.png',
  },
  {
    id: 109,
    label: 'Italy',
    value: '+39',
    flag: 'https://www.countryflags.io/IT/flat/64.png',
  },
  {
    id: 110,
    label: "Ivory Coast / Cote d'Ivoire",
    value: '+225',
    flag: 'https://www.countryflags.io/CI/flat/64.png',
  },
  {
    id: 111,
    label: 'Jamaica',
    value: '+1876',
    flag: 'https://www.countryflags.io/JM/flat/64.png',
  },
  {
    id: 112,
    label: 'Japan',
    value: '+81',
    flag: 'https://www.countryflags.io/JP/flat/64.png',
  },
  {
    id: 113,
    label: 'Jersey',
    value: '+44',
    flag: 'https://www.countryflags.io/JE/flat/64.png',
  },
  {
    id: 114,
    label: 'Jordan',
    value: '+962',
    flag: 'https://www.countryflags.io/JO/flat/64.png',
  },
  {
    id: 115,
    label: 'Kazakhstan',
    value: '+77',
    flag: 'https://www.countryflags.io/KZ/flat/64.png',
  },
  {
    id: 116,
    label: 'Kenya',
    value: '+254',
    flag: 'https://www.countryflags.io/KE/flat/64.png',
  },
  {
    id: 117,
    label: 'Kiribati',
    value: '+686',
    flag: 'https://www.countryflags.io/KI/flat/64.png',
  },
  {
    id: 118,
    label: "Korea, Democratic People's Republic of Korea",
    value: '+850',
    flag: 'https://www.countryflags.io/KP/flat/64.png',
  },
  {
    id: 119,
    label: 'Republic of Korea',
    value: '+82',
    flag: 'https://www.countryflags.io/KR/flat/64.png',
  },
  {
    id: 120,
    label: 'Kosovo',
    value: '+383',
    flag: 'https://www.countryflags.io/XK/flat/64.png',
  },
  {
    id: 121,
    label: 'Kuwait',
    value: '+965',
    flag: 'https://www.countryflags.io/KW/flat/64.png',
  },
  {
    id: 122,
    label: 'Kyrgyzstan',
    value: '+996',
    flag: 'https://www.countryflags.io/KG/flat/64.png',
  },
  {
    id: 123,
    label: 'Laos',
    value: '+856',
    flag: 'https://www.countryflags.io/LA/flat/64.png',
  },
  {
    id: 124,
    label: 'Latvia',
    value: '+371',
    flag: 'https://www.countryflags.io/LV/flat/64.png',
  },
  {
    id: 125,
    label: 'Lebanon',
    value: '+961',
    flag: 'https://www.countryflags.io/LB/flat/64.png',
  },
  {
    id: 126,
    label: 'Lesotho',
    value: '+266',
    flag: 'https://www.countryflags.io/LS/flat/64.png',
  },
  {
    id: 127,
    label: 'Liberia',
    value: '+231',
    flag: 'https://www.countryflags.io/LR/flat/64.png',
  },
  {
    id: 128,
    label: 'Libya',
    value: '+218',
    flag: 'https://www.countryflags.io/LY/flat/64.png',
  },
  {
    id: 129,
    label: 'Liechtenstein',
    value: '+423',
    flag: 'https://www.countryflags.io/LI/flat/64.png',
  },
  {
    id: 130,
    label: 'Lithuania',
    value: '+370',
    flag: 'https://www.countryflags.io/LT/flat/64.png',
  },
  {
    id: 131,
    label: 'Luxembourg',
    value: '+352',
    flag: 'https://www.countryflags.io/LU/flat/64.png',
  },
  {
    id: 132,
    label: 'Macau',
    value: '+853',
    flag: 'https://www.countryflags.io/MO/flat/64.png',
  },
  {
    id: 133,
    label: 'Madagascar',
    value: '+261',
    flag: 'https://www.countryflags.io/MG/flat/64.png',
  },
  {
    id: 134,
    label: 'Malawi',
    value: '+265',
    flag: 'https://www.countryflags.io/MW/flat/64.png',
  },
  {
    id: 135,
    label: 'Malaysia',
    value: '+60',
    flag: 'https://www.countryflags.io/MY/flat/64.png',
  },
  {
    id: 136,
    label: 'Maldives',
    value: '+960',
    flag: 'https://www.countryflags.io/MV/flat/64.png',
  },
  {
    id: 137,
    label: 'Mali',
    value: '+223',
    flag: 'https://www.countryflags.io/ML/flat/64.png',
  },
  {
    id: 138,
    label: 'Malta',
    value: '+356',
    flag: 'https://www.countryflags.io/MT/flat/64.png',
  },
  {
    id: 139,
    label: 'Marshall Islands',
    value: '+692',
    flag: 'https://www.countryflags.io/MH/flat/64.png',
  },
  {
    id: 140,
    label: 'Martinique',
    value: '+596',
    flag: 'https://www.countryflags.io/MQ/flat/64.png',
  },
  {
    id: 141,
    label: 'Mauritania',
    value: '+222',
    flag: 'https://www.countryflags.io/MR/flat/64.png',
  },
  {
    id: 142,
    label: 'Mauritius',
    value: '+230',
    flag: 'https://www.countryflags.io/MU/flat/64.png',
  },
  {
    id: 143,
    label: 'Mayotte',
    value: '+262',
    flag: 'https://www.countryflags.io/YT/flat/64.png',
  },
  {
    id: 144,
    label: 'Mexico',
    value: '+52',
    flag: 'https://www.countryflags.io/MX/flat/64.png',
  },
  {
    id: 145,
    label: 'Micronesia, Federated States of Micronesia',
    value: '+691',
    flag: 'https://www.countryflags.io/FM/flat/64.png',
  },
  {
    id: 146,
    label: 'Moldova',
    value: '+373',
    flag: 'https://www.countryflags.io/MD/flat/64.png',
  },
  {
    id: 147,
    label: 'Monaco',
    value: '+377',
    flag: 'https://www.countryflags.io/MC/flat/64.png',
  },
  {
    id: 148,
    label: 'Mongolia',
    value: '+976',
    flag: 'https://www.countryflags.io/MN/flat/64.png',
  },
  {
    id: 149,
    label: 'Montenegro',
    value: '+382',
    flag: 'https://www.countryflags.io/ME/flat/64.png',
  },
  {
    id: 150,
    label: 'Montserrat',
    value: '+1664',
    flag: 'https://www.countryflags.io/MS/flat/64.png',
  },
  {
    id: 151,
    label: 'Morocco',
    value: '+212',
    flag: 'https://www.countryflags.io/MA/flat/64.png',
  },
  {
    id: 152,
    label: 'Mozambique',
    value: '+258',
    flag: 'https://www.countryflags.io/MZ/flat/64.png',
  },
  {
    id: 153,
    label: 'Myanmar',
    value: '+95',
    flag: 'https://www.countryflags.io/MM/flat/64.png',
  },
  {
    id: 154,
    label: 'Namibia',
    value: '+264',
    flag: 'https://www.countryflags.io/NA/flat/64.png',
  },
  {
    id: 155,
    label: 'Nauru',
    value: '+674',
    flag: 'https://www.countryflags.io/NR/flat/64.png',
  },
  {
    id: 156,
    label: 'Nepal',
    value: '+977',
    flag: 'https://www.countryflags.io/NP/flat/64.png',
  },
  {
    id: 157,
    label: 'Netherlands',
    value: '+31',
    flag: 'https://www.countryflags.io/NL/flat/64.png',
  },
  {
    id: 158,
    label: 'Netherlands Antilles',
    value: '+599',
    flag: 'https://www.countryflags.io/AN/flat/64.png',
  },
  {
    id: 159,
    label: 'New Caledonia',
    value: '+687',
    flag: 'https://www.countryflags.io/NC/flat/64.png',
  },
  {
    id: 160,
    label: 'New Zealand',
    value: '+64',
    flag: 'https://www.countryflags.io/NZ/flat/64.png',
  },
  {
    id: 161,
    label: 'Nicaragua',
    value: '+505',
    flag: 'https://www.countryflags.io/NI/flat/64.png',
  },
  {
    id: 162,
    label: 'Niger',
    value: '+227',
    flag: 'https://www.countryflags.io/NE/flat/64.png',
  },
  {
    id: 163,
    label: 'Nigeria',
    value: '+234',
    flag: 'https://www.countryflags.io/NG/flat/64.png',
  },
  {
    id: 164,
    label: 'Niue',
    value: '+683',
    flag: 'https://www.countryflags.io/NU/flat/64.png',
  },
  {
    id: 165,
    label: 'Norfolk Island',
    value: '+672',
    flag: 'https://www.countryflags.io/NF/flat/64.png',
  },
  {
    id: 166,
    label: 'North Macedonia',
    value: '+389',
    flag: 'https://www.countryflags.io/MK/flat/64.png',
  },
  {
    id: 167,
    label: 'Northern Mariana Islands',
    value: '+1670',
    flag: 'https://www.countryflags.io/MP/flat/64.png',
  },
  {
    id: 168,
    label: 'Norway',
    value: '+47',
    flag: 'https://www.countryflags.io/NO/flat/64.png',
  },
  {
    id: 169,
    label: 'Oman',
    value: '+968',
    flag: 'https://www.countryflags.io/OM/flat/64.png',
  },
  {
    id: 170,
    label: 'Pakistan',
    value: '+92',
    flag: 'https://www.countryflags.io/PK/flat/64.png',
  },
  {
    id: 171,
    label: 'Palau',
    value: '+680',
    flag: 'https://www.countryflags.io/PW/flat/64.png',
  },
  {
    id: 172,
    label: 'Palestine',
    value: '+970',
    flag: 'https://www.countryflags.io/PS/flat/64.png',
  },
  {
    id: 173,
    label: 'Panama',
    value: '+507',
    flag: 'https://www.countryflags.io/PA/flat/64.png',
  },
  {
    id: 174,
    label: 'Papua New Guinea',
    value: '+675',
    flag: 'https://www.countryflags.io/PG/flat/64.png',
  },
  {
    id: 175,
    label: 'Paraguay',
    value: '+595',
    flag: 'https://www.countryflags.io/PY/flat/64.png',
  },
  {
    id: 176,
    label: 'Peru',
    value: '+51',
    flag: 'https://www.countryflags.io/PE/flat/64.png',
  },
  {
    id: 177,
    label: 'Philippines',
    value: '+63',
    flag: 'https://www.countryflags.io/PH/flat/64.png',
  },
  {
    id: 178,
    label: 'Pitcairn',
    value: '+872',
    flag: 'https://www.countryflags.io/PN/flat/64.png',
  },
  {
    id: 179,
    label: 'Poland',
    value: '+48',
    flag: 'https://www.countryflags.io/PL/flat/64.png',
  },
  {
    id: 180,
    label: 'Portugal',
    value: '+351',
    flag: 'https://www.countryflags.io/PT/flat/64.png',
  },
  {
    id: 181,
    label: 'Puerto Rico',
    value: '+1939',
    flag: 'https://www.countryflags.io/PR/flat/64.png',
  },
  {
    id: 182,
    label: 'Qatar',
    value: '+974',
    flag: 'https://www.countryflags.io/QA/flat/64.png',
  },
  {
    id: 183,
    label: 'Reunion',
    value: '+262',
    flag: 'https://www.countryflags.io/RE/flat/64.png',
  },
  {
    id: 184,
    label: 'Romania',
    value: '+40',
    flag: 'https://www.countryflags.io/RO/flat/64.png',
  },
  {
    id: 185,
    label: 'Russia',
    value: '+7',
    flag: 'https://www.countryflags.io/RU/flat/64.png',
  },
  {
    id: 186,
    label: 'Rwanda',
    value: '+250',
    flag: 'https://www.countryflags.io/RW/flat/64.png',
  },
  {
    id: 187,
    label: 'Saint Barthelemy',
    value: '+590',
    flag: 'https://www.countryflags.io/BL/flat/64.png',
  },
  {
    id: 188,
    label: 'Saint Helena, Ascension and Tristan Da Cunha',
    value: '+290',
    flag: 'https://www.countryflags.io/SH/flat/64.png',
  },
  {
    id: 189,
    label: 'Saint Kitts and Nevis',
    value: '+1869',
    flag: 'https://www.countryflags.io/KN/flat/64.png',
  },
  {
    id: 190,
    label: 'Saint Lucia',
    value: '+1758',
    flag: 'https://www.countryflags.io/LC/flat/64.png',
  },
  {
    id: 191,
    label: 'Saint Martin',
    value: '+590',
    flag: 'https://www.countryflags.io/MF/flat/64.png',
  },
  {
    id: 192,
    label: 'Saint Pierre and Miquelon',
    value: '+508',
    flag: 'https://www.countryflags.io/PM/flat/64.png',
  },
  {
    id: 193,
    label: 'Saint Vincent and the Grenadines',
    value: '+1784',
    flag: 'https://www.countryflags.io/VC/flat/64.png',
  },
  {
    id: 194,
    label: 'Samoa',
    value: '+685',
    flag: 'https://www.countryflags.io/WS/flat/64.png',
  },
  {
    id: 195,
    label: 'San Marino',
    value: '+378',
    flag: 'https://www.countryflags.io/SM/flat/64.png',
  },
  {
    id: 196,
    label: 'Sao Tome and Principe',
    value: '+239',
    flag: 'https://www.countryflags.io/ST/flat/64.png',
  },
  {
    id: 197,
    label: 'Saudi Arabia',
    value: '+966',
    flag: 'https://www.countryflags.io/SA/flat/64.png',
  },
  {
    id: 198,
    label: 'Senegal',
    value: '+221',
    flag: 'https://www.countryflags.io/SN/flat/64.png',
  },
  {
    id: 199,
    label: 'Serbia',
    value: '+381',
    flag: 'https://www.countryflags.io/RS/flat/64.png',
  },
  {
    id: 200,
    label: 'Seychelles',
    value: '+248',
    flag: 'https://www.countryflags.io/SC/flat/64.png',
  },
  {
    id: 201,
    label: 'Sierra Leone',
    value: '+232',
    flag: 'https://www.countryflags.io/SL/flat/64.png',
  },
  {
    id: 202,
    label: 'Sint Maarten',
    value: '+1721',
    flag: 'https://www.countryflags.io/SX/flat/64.png',
  },
  {
    id: 203,
    label: 'Slovakia',
    value: '+421',
    flag: 'https://www.countryflags.io/SK/flat/64.png',
  },
  {
    id: 204,
    label: 'Slovenia',
    value: '+386',
    flag: 'https://www.countryflags.io/SI/flat/64.png',
  },
  {
    id: 205,
    label: 'Solomon Islands',
    value: '+677',
    flag: 'https://www.countryflags.io/SB/flat/64.png',
  },
  {
    id: 206,
    label: 'Somalia',
    value: '+252',
    flag: 'https://www.countryflags.io/SO/flat/64.png',
  },
  {
    id: 207,
    label: 'South Africa',
    value: '+27',
    flag: 'https://www.countryflags.io/ZA/flat/64.png',
  },
  {
    id: 208,
    label: 'South Georgia and the South Sandwich Islands',
    value: '+500',
    flag: 'https://www.countryflags.io/GS/flat/64.png',
  },
  {
    id: 209,
    label: 'South Sudan',
    value: '+211',
    flag: 'https://www.countryflags.io/SS/flat/64.png',
  },
  {
    id: 210,
    label: 'Spain',
    value: '+34',
    flag: 'https://www.countryflags.io/ES/flat/64.png',
  },
  {
    id: 211,
    label: 'Sri Lanka',
    value: '+94',
    flag: 'https://www.countryflags.io/LK/flat/64.png',
  },
  {
    id: 212,
    label: 'Sudan',
    value: '+249',
    flag: 'https://www.countryflags.io/SD/flat/64.png',
  },
  {
    id: 213,
    label: 'Surilabel',
    value: '+597',
    flag: 'https://www.countryflags.io/SR/flat/64.png',
  },
  {
    id: 214,
    label: 'Svalbard and Jan Mayen',
    value: '+47',
    flag: 'https://www.countryflags.io/SJ/flat/64.png',
  },
  {
    id: 215,
    label: 'Sweden',
    value: '+46',
    flag: 'https://www.countryflags.io/SE/flat/64.png',
  },
  {
    id: 216,
    label: 'Switzerland',
    value: '+41',
    flag: 'https://www.countryflags.io/CH/flat/64.png',
  },
  {
    id: 217,
    label: 'Syrian Arab Republic',
    value: '+963',
    flag: 'https://www.countryflags.io/SY/flat/64.png',
  },
  {
    id: 218,
    label: 'Taiwan',
    value: '+886',
    flag: 'https://www.countryflags.io/TW/flat/64.png',
  },
  {
    id: 219,
    label: 'Tajikistan',
    value: '+992',
    flag: 'https://www.countryflags.io/TJ/flat/64.png',
  },
  {
    id: 220,
    label: 'Tanzania, United Republic of Tanzania',
    value: '+255',
    flag: 'https://www.countryflags.io/TZ/flat/64.png',
  },
  {
    id: 221,
    label: 'Thailand',
    value: '+66',
    flag: 'https://www.countryflags.io/TH/flat/64.png',
  },
  {
    id: 222,
    label: 'Timor-Leste',
    value: '+670',
    flag: 'https://www.countryflags.io/TL/flat/64.png',
  },
  {
    id: 223,
    label: 'Togo',
    value: '+228',
    flag: 'https://www.countryflags.io/TG/flat/64.png',
  },
  {
    id: 224,
    label: 'Tokelau',
    value: '+690',
    flag: 'https://www.countryflags.io/TK/flat/64.png',
  },
  {
    id: 225,
    label: 'Tonga',
    value: '+676',
    flag: 'https://www.countryflags.io/TO/flat/64.png',
  },
  {
    id: 226,
    label: 'Trinidad and Tobago',
    value: '+1868',
    flag: 'https://www.countryflags.io/TT/flat/64.png',
  },
  {
    id: 227,
    label: 'Tunisia',
    value: '+216',
    flag: 'https://www.countryflags.io/TN/flat/64.png',
  },
  {
    id: 228,
    label: 'Turkey',
    value: '+90',
    flag: 'https://www.countryflags.io/TR/flat/64.png',
  },
  {
    id: 229,
    label: 'Turkmenistan',
    value: '+993',
    flag: 'https://www.countryflags.io/TM/flat/64.png',
  },
  {
    id: 230,
    label: 'Turks and Caicos Islands',
    value: '+1649',
    flag: 'https://www.countryflags.io/TC/flat/64.png',
  },
  {
    id: 231,
    label: 'Tuvalu',
    value: '+688',
    flag: 'https://www.countryflags.io/TV/flat/64.png',
  },
  {
    id: 232,
    label: 'Uganda',
    value: '+256',
    flag: 'https://www.countryflags.io/UG/flat/64.png',
  },
  {
    id: 233,
    label: 'Ukraine',
    value: '+380',
    flag: 'https://www.countryflags.io/UA/flat/64.png',
  },
  {
    id: 234,
    label: 'United Arab Emirates',
    value: '+971',
    flag: 'https://www.countryflags.io/AE/flat/64.png',
  },
  {
    id: 235,
    label: 'Uruguay',
    value: '+598',
    flag: 'https://www.countryflags.io/UY/flat/64.png',
  },
  {
    id: 236,
    label: 'Uzbekistan',
    value: '+998',
    flag: 'https://www.countryflags.io/UZ/flat/64.png',
  },
  {
    id: 237,
    label: 'Vanuatu',
    value: '+678',
    flag: 'https://www.countryflags.io/VU/flat/64.png',
  },
  {
    id: 238,
    label: 'Venezuela, Bolivarian Republic of Venezuela',
    value: '+58',
    flag: 'https://www.countryflags.io/VE/flat/64.png',
  },
  {
    id: 239,
    label: 'Vietnam',
    value: '+84',
    flag: 'https://www.countryflags.io/VN/flat/64.png',
  },
  {
    id: 240,
    label: 'British Virgin Islands',
    value: '+1284',
    flag: 'https://www.countryflags.io/VG/flat/64.png',
  },
  {
    id: 241,
    label: 'United States Virgin Islands',
    value: '+1340',
    flag: 'https://www.countryflags.io/VI/flat/64.png',
  },
  {
    id: 242,
    label: 'Wallis and Futuna',
    value: '+681',
    flag: 'https://www.countryflags.io/WF/flat/64.png',
  },
  {
    id: 243,
    label: 'Yemen',
    value: '+967',
    flag: 'https://www.countryflags.io/YE/flat/64.png',
  },
  {
    id: 244,
    label: 'Zambia',
    value: '+260',
    flag: 'https://www.countryflags.io/ZM/flat/64.png',
  },
  {
    id: 245,
    label: 'Zimbabwe',
    value: '+263',
    flag: 'https://www.countryflags.io/ZW/flat/64.png',
  },
];
