import { IPortfolioWineItemInterface } from '../../types/PortfolioTypes';

const wineTableData: Array<IPortfolioWineItemInterface> = [
  {
    wineName: 'Rare',
    wineRegion: 'Champagne',
    unitSize: '3x75cl',
    wineVintage: 2006,
    totalPrice: 380,
    qty: 1,
  },
  {
    wineName: 'Beychevelle',
    wineRegion: 'Bordeaux',
    unitSize: '6x75cl',
    wineVintage: 2019,
    totalPrice: 460,
    qty: 1,
  },
  {
    wineName: 'Pontet Canet',
    wineRegion: 'Bordeaux',
    unitSize: '6x75cl',
    wineVintage: 2019,
    totalPrice: 470,
    qty: 1,
  },
];

export default wineTableData;
