enum ProgressBarTitleEnum {
  PERSONAL_DETAILS = 'Personal Details',
  INVESTOR_PROFILE = 'Investor Profile',
  ADDITIONAL_INFORMATION = 'Additional Information',
  ACCOUNT_FUNDING = 'Account Funding',
}

enum ProgressBarValueEnum {
  PERSONAL_DETAILS = 0,
  INVESTOR_PROFILE = 1,
  ADDITIONAL_INFORMATION = 2,
  ACCOUNT_FUNDING = 3,
}

export { ProgressBarTitleEnum, ProgressBarValueEnum };
