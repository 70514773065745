/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { combineReducers } from 'redux';
import { removeUserSession } from '../utils/auth/authCommon';
import { LOGOUT, reducer as authReducer } from './auth/reducer';
import { reducer as pageReducer } from './page/reducer';
import { reducer as portfolioReducer } from './portfolio/reducer';
import { reducer as progressBarReducer } from './progressBar/reducer';
import { reducer as settingsReducer } from './settings/reducer';
import { reducer as uiReducer } from './ui/reducer';

const appReducer = combineReducers({
  portfolioState: portfolioReducer,
  progressBarState: progressBarReducer,
  settingsState: settingsReducer,
  authState: authReducer,
  pageState: pageReducer,
  uiState: uiReducer,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT) {
    removeUserSession();
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
