/* eslint-disable @typescript-eslint/no-explicit-any */
export const SHOW_AUTH_CODE_INPUT = 'showAuthCodeInput';
export const ALLOW_CONTINUE = 'allowContinue';
export const DISABLE_PROPERTY = 'disableProperty';

export interface IUIState {
  showInputBox: boolean;
  allowContinue: boolean;
  disabled: {
    investmentTerm: boolean;
    investmentStyle: boolean;
    investmentCapital: boolean;
  };
}

interface IAction {
  type: string;
  payload: boolean | any;
}

export const initialState: IUIState = {
  showInputBox: false,
  allowContinue: true,
  disabled: {
    investmentTerm: false,
    investmentStyle: false,
    investmentCapital: false,
  },
};

export const reducer = (state = initialState, action: IAction): IUIState => {
  switch (action.type) {
    case SHOW_AUTH_CODE_INPUT:
      return {
        ...state,
        showInputBox: action.payload,
      };
    case ALLOW_CONTINUE:
      return {
        ...state,
        allowContinue: action.payload,
      };
    case DISABLE_PROPERTY:
      // eslint-disable-next-line no-case-declarations
      const payload = {
        ...state.disabled,
        [action.payload.propertyToDisable]: action.payload.isDisabled,
      };
      return {
        ...state,
        disabled: payload,
      };
    default:
      return state;
  }
};

export function setShowAuthCodeInputBoxAction(showInputBox: boolean): IAction {
  return {
    type: SHOW_AUTH_CODE_INPUT,
    payload: showInputBox,
  };
}

export function allowContinueAction(allowContinue: boolean): IAction {
  return {
    type: ALLOW_CONTINUE,
    payload: allowContinue,
  };
}

export function disableProperty(propertyToDisable: string, isDisabled: boolean): IAction {
  return {
    type: DISABLE_PROPERTY,
    payload: {
      propertyToDisable,
      isDisabled,
    },
  };
}
