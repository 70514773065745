export type PageNames = 'investmentSumAmount' | 'fundYourAccount';

enum PageUrls {
  LOGIN = '/login',
  PERSONAL_DETAILS = '/personal-details',
  INVESTOR_PROFILE = '/investor-profile',
  ACCOUNT_FUNDING = '/account-funding',
  ADDITIONAL_INFORMATION = '/additional-information',
  ADDITIONAL_INFORMATION_SUCCESS = '/additional-information-success',
  ERROR = '/error',
}

export default PageUrls;
