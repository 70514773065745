import PageUrls from '../../lib/enums/pageNameEnum';

export const PROGRESS_STEP = 'progressStep';
export const PROGRESS_ROUTE = 'progressRoute';

export interface IPageStepCompletedState {
  currentRoute: PageUrls;
}

interface IAction {
  type: string;
  payload: PageUrls;
}

export const initialState: IPageStepCompletedState = {
  currentRoute: PageUrls.PERSONAL_DETAILS,
};

export const reducer = (state = initialState, action: IAction): IPageStepCompletedState => {
  switch (action.type) {
    case PROGRESS_ROUTE:
      return {
        ...state,
        currentRoute: action.payload as PageUrls,
      };
    default:
      return state;
  }
};

export function updateRouteAction(currentRoute: PageUrls): IAction {
  return {
    type: PROGRESS_ROUTE,
    payload: currentRoute,
  };
}
