import { ISessionInterface } from '../../types/SessionType';

// return the user data from the session storage
interface ILoggedInUser {
  token: string;
}

export const getUser = (): ILoggedInUser | null => {
  const userStr = sessionStorage.getItem('user');
  return userStr ? JSON.parse(userStr) : null;
};

// return the token from the session storage
export const getToken = (type: string): string | null => {
  return localStorage.getItem(type) || null;
};

// check to see if uer is logged
export const isUserLogged = (): boolean => {
  if (getToken('ACCESS_TOKEN')) return true;
  return false;
};

// remove the token and user from the session storage
export const removeUserSession = (): void => {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('USER_TOKEN');
  localStorage.removeItem('state');
  localStorage.removeItem('persist:root-secondary');
};

// set the session in localstorage
export const setUserSession = (session: ISessionInterface): void => {
  localStorage.setItem('SESSION', JSON.stringify(session));
};

export const getRequestHeaders = (): { Authorization: string; CdsUserJwt: string } => {
  const accessToken = getToken('ACCESS_TOKEN');
  const JTWToken = getToken('USER_TOKEN');

  return {
    Authorization: `Bearer ${accessToken}`,
    CdsUserJwt: `${JTWToken}`,
  };
};
