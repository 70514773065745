import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import reducers from './redux/rootReducer';
import './stylesheets/custom.scss';

const persistConfig = {
  key: 'root-getchip',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
const middlewares = [thunk];
const middlewareEnhancer = applyMiddleware(...middlewares);
const enhancers = [middlewareEnhancer];
const composedEnhancers = composeWithDevTools(...enhancers);

export const store = createStore(persistedReducer, composedEnhancers);

export const persistor = persistStore(store);

export type OnboardingState = ReturnType<typeof store.getState>;
