import React from 'react';
import Logo from '../../assets/images/dual-branding-logo.png';
import './index.scss';

export default function Closed(): JSX.Element {
  return (
    <div className="container">
      <img src={Logo} alt="cw&chip" />
      <p>
        Unfortunately, this link has expired and we are unable to offer this portfolio of wines. If you are still
        interested in investing in wine, please get in touch with Cult Wine Investment directly by{' '}
        <a href="https://www.wineinvestment.com/how-it-works/investing-with-us/">visiting our website</a>
      </p>
    </div>
  );
}
