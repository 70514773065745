import { ISessionResponse } from '../types/SessionType';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state: ISessionResponse) => {
  try {
    localStorage.setItem('state', JSON.stringify(state));
  } catch {
    // ignore write errors
  }
};

export { loadState, saveState };
