import { ProgressBarTitleEnum, ProgressBarValueEnum } from '../../lib/enums/progressBarTitleEnum';

export const UPDATE_PROGRESS_BAR = 'updateProgressBar';

export interface IProgressBarState {
  name: string;
  value: number;
}

interface IAction {
  type: string;
  payload: IProgressBarState;
}

export const initialState: IProgressBarState = {
  name: ProgressBarTitleEnum.INVESTOR_PROFILE,
  value: ProgressBarValueEnum.INVESTOR_PROFILE,
};

export const reducer = (state = initialState, action: IAction): IProgressBarState => {
  switch (action.type) {
    case UPDATE_PROGRESS_BAR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export function updateProgressBarAction(progressBarPayload: IProgressBarState): IAction {
  return {
    type: UPDATE_PROGRESS_BAR,
    payload: progressBarPayload,
  };
}
