/* eslint-disable no-console */
import axios from 'axios';
import { ISessionInterface, ISessionResponse } from '../types/SessionType';
import { getRequestHeaders } from '../utils/auth/authCommon';

const BASE_URL = `${process.env.REACT_APP_ONBOARDING_SESSION_SERVICE_URL}OnboardingSession/`;

export async function createUserSession(state: ISessionInterface): Promise<ISessionResponse> {
  const response = await axios.post(`${BASE_URL}CreateSession`, state, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function progressSession(state: ISessionInterface): Promise<ISessionResponse> {
  const response = await axios.post(`${BASE_URL}ProgressSession`, state, {
    headers: {
      ...getRequestHeaders(),
      'Content-Type': 'application/json',
    },
  });

  return response.data;
}

export async function getAllSessions(): Promise<ISessionResponse[]> {
  const headers = getRequestHeaders();
  const response = await axios.get(`${BASE_URL}GetAllSessions`, {
    headers,
  });
  return response.data;
}

export async function getSessionById(): Promise<void> {
  console.log('code to come');
}
