import { IAction } from './actions';
import { SET_LOCALE } from './constants';
import { Locale } from './types';

export interface ISettingsState {
  locale: Locale;
}

const initialState: ISettingsState = {
  locale: 'UK',
};

export const reducer = (state = initialState, { type, payload }: IAction): ISettingsState => {
  switch (type) {
    case SET_LOCALE:
      return {
        ...state,
        locale: payload,
      };
    default:
      return state;
  }
};
