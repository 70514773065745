import countriesJSON from '../../assets/countries-codes';
import { ClientPaymentTypeEnum, ClientTypeEnum } from '../../lib/enums/clientTypeEnum';
import { TierInvestmentLevelEnum } from '../../lib/enums/tierInvestmentLevelEnum';
import { IClientAddressResponse } from '../../types/ClientTypes';
import { IInvestCommitteeResponseItem } from '../../types/investCommitteeTypes';
import { IPortfolioWineItemInterface } from '../../types/PortfolioTypes';
import { IClientDetailsInterface } from '../../types/RegistrationDetails';
import { AvailableCurrency } from '../settings/constants';
import { IAction } from './actions';
import wineTableData from '../../components/tables/winePortfolioData';
import {
  SET_CURRENCY,
  UPDATE_CLIENT_ADDRESS,
  UPDATE_INVESTED_CAPITAL_AMOUNT,
  UPDATE_PORTFOLIO,
  UPDATE_USER_DETAILS,
} from './constants';

export interface IPortfolioState {
  userDetails: IClientDetailsInterface;
  portfolioWineAllocation: IInvestCommitteeResponseItem[];
  clientAddress: IClientAddressResponse;
  currency: AvailableCurrency;
  portfolio: IPortfolioWineItemInterface[];
}

const initialState: IPortfolioState = {
  userDetails: {
    firstName: 'John',
    lastName: 'Doe',
    email: '',
    telephone: '',
    dateOfBirth: '',
    additionalName1: '',
    additionalName2: '',
    alternativeNameOrEntity: '',
    country: countriesJSON[0].label,
    hasContractSigned: false,
    hasPortfolioConfirmed: false,
    clientType: ClientTypeEnum.GET_CHIP,
    hasPaymentConfirmed: false,
    clientCCY: AvailableCurrency.Gbp,
    telephoneCountryCode: countriesJSON.find((country) => country.id === 1)!.value,
    investedCapitalAmount: wineTableData.map((d) => d.totalPrice * d.qty).reduce((prev, curr) => prev + curr, 0),
    investmentStyle: null,
    investmentTerm: null,
    tierInvestmentLevel: TierInvestmentLevelEnum.CruClasse,
    hasClientEmailSentSuccessfully: false,
    clientLocale: undefined,
    clientPaymentType: ClientPaymentTypeEnum.UNKNOWN,
    paymentReference: null,
    diallingCode: '',
    hasMarketingConsentGiven: false,
    hasPrivacyPolicyAgreed: false,
    hasTermsAndConditionsAccepted: false,
  },
  clientAddress: {
    addressType: '',
    addressLine1: '',
    addressLine2: '',
    cityOrTownName: '',
    county: '',
    country: '',
    postcode: '',
    addressId: '',
    lastModifiedDate: '',
    createdDate: '',
  },
  portfolioWineAllocation: [],
  portfolio: [],
  currency: AvailableCurrency.Gbp,
};

export const reducer = (state = initialState, { type, payload }: IAction): IPortfolioState => {
  switch (type) {
    case UPDATE_INVESTED_CAPITAL_AMOUNT:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          investedCapitalAmount: payload as number,
        },
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          ...(payload as IClientDetailsInterface),
        },
      };
    case UPDATE_CLIENT_ADDRESS:
      return {
        ...state,
        clientAddress: {
          ...state.clientAddress,
          ...(payload as IClientAddressResponse),
        },
      };
    case SET_CURRENCY:
      return {
        ...state,
        currency: payload as AvailableCurrency,
      };
    case UPDATE_PORTFOLIO:
      return {
        ...state,
        portfolio: payload as IPortfolioWineItemInterface[],
      };

    default:
      return state;
  }
};
