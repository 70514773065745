enum ClientTypeEnum {
  GET_CHIP = 'GetChip',
}

enum RegisteredByEnum {
  GET_CHIP = 'GetChip',
}

enum ClientPaymentTypeEnum {
  UNKNOWN = 'Unknown',
  CARD = 'Card',
}

enum SessionTypeEnum {
  GET_CHIP = 'GetChip',
}

export { ClientTypeEnum, RegisteredByEnum, ClientPaymentTypeEnum, SessionTypeEnum };
