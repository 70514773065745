import { Dispatch } from 'redux';
import { createUserSession, progressSession } from '../../services/onboardingSession.service';
import { ISessionInterface } from '../../types/SessionType';
import { IError } from '../../types/ErrorTypes';
import { removeUserSession } from '../../utils/auth/authCommon';
import { saveState } from '../../utils/stateStorage';

export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const UPDATE_SESSION = 'updateSession';
export const SET_ERROR = 'setError';

export interface IAuthState {
  isLogged: boolean;
  session: ISessionInterface;
  error: IError;
}

interface IAction {
  type: string;
  payload: boolean | ISessionInterface | IError;
}

const initialState = {
  isLogged: false,
  session: {
    action: 'page-start',
    info: '',
    sessionType: 'ManagedClient',
    sessionId: '',
  },
  error: {
    isError: false,
    error: {
      statusCode: null,
      message: '',
    },
  },
};

export const reducer = (state: IAuthState = initialState, action: IAction): IAuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLogged: action.payload as boolean,
      };
    case UPDATE_SESSION:
      return {
        ...state,
        session: { ...(action.payload as ISessionInterface) },
      };
    case SET_ERROR:
      return {
        ...state,
        error: { ...(action.payload as IError) },
      };
    default:
      return state;
  }
};

export function setErrorAction(error: IError): IAction {
  return {
    type: SET_ERROR,
    payload: error,
  };
}

export function clearErrorAction(): IAction {
  return {
    type: SET_ERROR,
    payload: initialState.error,
  };
}

export function loginAction(): IAction {
  return {
    type: LOGIN,
    payload: true,
  };
}

export function logoutAction(): IAction {
  return {
    type: LOGOUT,
    payload: false,
  };
}

export function updateSessionAction(session: ISessionInterface): IAction {
  return {
    type: UPDATE_SESSION,
    payload: session,
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleErrorFactory = (error: any): IError => ({
  isError: true,
  error: {
    statusCode: error.response.status,
    message: error.message,
  },
});

export const logoutAndRemoveSession = () => {
  return async (dispatch: Dispatch): Promise<void> => {
    removeUserSession();
    dispatch(logoutAction());
  };
};

export const createSessionEffect = (state: ISessionInterface) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const statePayload = {
        ...state,
      };

      delete statePayload.info.authState.session.info;
      delete statePayload.info.authState.session.sessionHistories;

      const session = await createUserSession(statePayload);
      saveState(session);

      dispatch(updateSessionAction(session as unknown as ISessionInterface));
    } catch (error) {
      dispatch(setErrorAction(handleErrorFactory(error)));
    }
  };
};

export const progressSessionEffect = (state: ISessionInterface) => {
  return async (dispatch: Dispatch): Promise<void> => {
    try {
      const statePayload = {
        ...state,
      };

      delete statePayload.info.authState.session.info;
      delete statePayload.info.authState.session.sessionHistories;

      const session = await progressSession(statePayload);
      saveState(session);

      dispatch(updateSessionAction(session as unknown as ISessionInterface));
    } catch (error) {
      dispatch(setErrorAction(handleErrorFactory(error)));
    }
  };
};

export default reducer;
