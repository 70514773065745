export const UPDATE_INVESTMENT_STYLE = 'updateInvestmentStyle';
export const UPDATE_INVESTMENT_TERM = 'updateInvestmentTerm';
export const UPDATE_INVESTMENT_TIER = 'updateInvestmentTier';
export const UPDATE_USER_DETAILS = 'updateUserDetails';
export const UPDATE_CLIENT_ADDRESS = 'updateClientAddress';
export const UPDATE_PORTFOLIO = 'updatePortfolio';
export const SET_PORTFOLIO_LOADING = 'setPortfolioLoading';
export const SET_ALLOCATION_LOADING = 'setAllocationLoading';
export const SET_CURRENCY = 'setCurrency';
export const UPDATE_INVESTED_CAPITAL_AMOUNT = 'updateInvestedCapitalAmount';
