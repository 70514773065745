export type TierInvestmentLevel = 'CruClasse' | 'GetChip';

enum TierInvestmentLevelEnum {
  CruClasse = 'CruClasse',
}

enum TierInvestmentLevelNameEnum {
  CruClasse = 'Cru Classe',
}

export { TierInvestmentLevelEnum, TierInvestmentLevelNameEnum };
