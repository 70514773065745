/* eslint-disable import/prefer-default-export */
import { Locale } from './types';

export const countryByLocale = {
  UK: 'United Kingdom',
  HK: 'Hong Kong',
  SG: 'Singapore',
  US: 'United States',
  CA: 'Canada',
  CN: 'China',
} as Record<Locale, string>;

export const supportedLocales: Locale[] = ['UK'];

export enum AvailableCurrency {
  Gbp = 'GBP',
  Usd = 'USD',
  Cad = 'CAD',
  Sgd = 'SGD',
  Hkd = 'HKD',
  Cny = 'CNY',
}

export const currencyCodeByLocale = {
  UK: AvailableCurrency.Gbp,
  HK: AvailableCurrency.Hkd,
  SG: AvailableCurrency.Sgd,
  US: AvailableCurrency.Usd,
  CA: AvailableCurrency.Cad,
  CN: AvailableCurrency.Cny,
} as Record<Locale, AvailableCurrency>;

export const SET_LOCALE = 'setLocale';
